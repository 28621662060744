/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import mock09 from "../assets/images/mock09.png";
import mock10 from "../assets/images/mock10.png";
import "../assets/styles/Project.scss";

function Project() {
  return (
    <div className="projects-container" id="projects">
      <h1>Personal Projects</h1>
      <div className="projects-grid">
        <div className="project">
          <a href="#" target="_blank" rel="noreferrer">
            <img src={mock10} className="zoom" alt="thumbnail" width="100%" />
          </a>
          <a href="#" target="_blank" rel="noreferrer">
            <h2>Project 1</h2>
          </a>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione et
            itaque obcaecati rem quas molestiae?
          </p>
        </div>
        <div className="project">
          <a href="#" target="_blank" rel="noreferrer">
            <img src={mock09} className="zoom" alt="thumbnail" width="100%" />
          </a>
          <a href="#" target="_blank" rel="noreferrer">
            <h2>project 2</h2>
          </a>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus,
            assumenda quis non itaque veniam commodi.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Project;
